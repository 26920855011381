<template>
  <!-- <div>Trailer Deeplink component</div> -->
  <div>
    <div v-if="!dataFetched">
      <detailPageLoader></detailPageLoader>
    </div>
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";
export default {
  data() {
    return {
      contentId: null,
      localDisplayLang: null,
      contentDetails: null,
      dataFetched: false,
    };
  },
  created() {
    console.log("Created of trailer deeplink", this.$route.params.contentId);
    console.log("trailer id ", this.$route.query.trailerId);
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
    this.contentId = this.$route.params.contentId;
    this.getContentDetail(this.contentId);
    
    eventBus.$on(`contentDetail-response-${this.contentId}`, (response) => {
      console.log(
        "what is the response------------------from createdd  of trailer Deeplink --------------",
        response
      );
      let self = this;
      if (!response.reason) {
           this.dataFetched = true;
        console.log("DATA FETCHED SUCCESS-------");
        self.contentDetails = response.data;
        console.log("content details are ------------", this.contentDetails);
         self.createTrailerLink();
      } else if (reason.response) {
        console.log("DATA FETCH FAILED");
      }
    });
   
  },
  mounted() {
    console.log("IN MOUNTRED OF TRAILER DEEPLINK ", this.contentDetails);
  },
  methods: {
    getContentDetail(contentid) {
      let payload = {
        contentid: contentid,
        params: {
          displaylanguage: this.localDisplayLang,
        },
      };

      eventBus.$emit("getContentDetail", payload);
    },

    createTrailerLink() {
      // https://noorplay.com/series/The-Ottoman/gnOJj66iEQb7/trailer/yfo6ns1xmki/play
      // noorplay://content/play/lVkDjsD03Tqx/trailer/skdl9k29lsm
      console.log("createTrailerlinkk====", this.contentDetails.category);
      let url = window.location.origin;
      let contentType =
      this.contentDetails.category === "TVSHOW" ? "series" : "movie";
      let contentTitle = this.contentDetails.defaulttitle.replace(/\s/g, '-')
      let trailerId = this.$route.query.trailerId;
      console.log("url", url);
      let deeplink = `${url}/${contentType}/${contentTitle}/${this.contentId}/trailer/${trailerId}/play`;
      console.log("DEEPLINK CREATED========", deeplink);
      window.location.replace(deeplink)
      //this.$router.replace({path:deeplink})
    },
  },
   components: {
    detailPageLoader: () =>
      import(
        /* webpackChunkName: "detailPageLoader" */ "@/components/DetailPage/detailPageLoader.vue"
      ),
   }
};
</script>