<template>
  <div>
    <div v-if="!dataFetched">
      <detailPageLoader></detailPageLoader>
    </div>
  
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";

import { showSnackBar } from '@/utilities';

export default {
  data() {
    return {
      contentId: null,
      localDisplayLang: null,
      contentDetails: null,
      dataFetched: false,
      episodeId:null,
      episodeDetails:null,
      
    };
  },
  created() {
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
    this.episodeId = this.$route.params.contentId;
    
    this.getEpisodeDetail(this.episodeId);

    let subscriberProfileDetails = localStorage.getItem("subscriberProfileDetails");

    if (subscriberProfileDetails) {
      subscriberProfileDetails = JSON.parse(subscriberProfileDetails);
    }

    eventBus.$on(`contentDetail-error-response-${this.episodeId}`, (error) => {

      showSnackBar(this.$t("Content unavailable"),this.$t('DISMISS'));

      this.$router.push("/");
    })
    
    eventBus.$on(`contentDetail-response-${this.episodeId}`, (response) => {
      let self = this;
      if (!response.reason) {
        this.dataFetched = true;
        
        self.episodeDetails = response.data;
        
        if (
          subscriberProfileDetails &&
          subscriberProfileDetails.kidsmode === "YES" &&
          self.episodeDetails.defaultgenre !== "KIDS"
        ) {
          showSnackBar(this.$t("Content is not accessible"),this.$t('DISMISS'));
          this.$router.replace("/");
        } 

        self.createContentPlayLink(this.episodeDetails);
      } else if (reason.response) {
        console.log("Data fetch failed", response.reason);
      }
    });
  },
  methods: {
    getContentDetail(contentid) {
        console.log('episodde and content details ',contentid)
      let payload = {
        contentid: contentid,
        params: {
          displaylanguage: this.localDisplayLang,
        },
      };

      eventBus.$emit("getContentDetail", payload);
    },
    getEpisodeDetail(contentid) {
        console.log('inside getEpisodeDetail',contentid)
      let payload = {
        contentid: contentid,
        params: {
          displaylanguage: this.localDisplayLang,
        },
      };

      eventBus.$emit("getContentDetail", payload);
    },

    createContentPlayLink(episodeInfo) {
    //   http://localhost:8080/series/The-Ottoman/gnOJj66iEQb7/season/2/episode/jmgCPyLpHyVe/play
   //    http://localhost:8080/series/The-Ottoman/gnOJj66iEQb7/season/2/episode/jmgCPyLpHyVe/play
      console.log("createTrailerlink content play deeplink component====", episodeInfo);
      let url = window.location.origin;
      let contentType = episodeInfo.category === "TVSHOW" ? "series" : "movie";
      let contentTitle = episodeInfo.category === "TVSHOW" ? episodeInfo.defaulttitle.replace(/\s/g, '-') : episodeInfo.title.replace(/\s/g, '-')
      let contentId =  episodeInfo.category === "TVSHOW" ? episodeInfo.seriesid : episodeInfo.objectid
      let episodeId = this.$route.params.contentId;
      let season = episodeInfo.seasonnum
      let deeplink = " ";
      if(episodeInfo.category === "TVSHOW"){
         deeplink = `${url}/${contentType}/${contentTitle}/${contentId}/season/${season}/episode/${episodeId}/play`;
      }else {
        //   http://localhost:8080/movie/Sword-of-Justice/lVkDjsD03Tqx/play
         deeplink = `${url}/${contentType}/${contentTitle}/${contentId}/play`
      }
      console.log("DEEPLINK CREATED========", deeplink);
      
      window.location.replace(deeplink)
      //this.$router.replace({path:deeplink})
    },
  },
   components: {
    detailPageLoader: () =>
      import(
        /* webpackChunkName: "detailPageLoader" */ "@/components/DetailPage/detailPageLoader.vue"
      ),
   }
};
</script>